main {
  margin: 0 auto;
  width: 100%;
  max-width: 100rem;
  padding: 12.5rem 9.375rem;
}

.main-content {
  counter-reset: section 0;
}

main.fill-height {
  padding: 0 9.375rem;
}

section {
  margin: 0 auto;
  max-width: 62.5rem;
}

.big-heading {
  margin: 0;
  font-size: clamp(2.5rem, 8vw, 5rem);
}

p {
  margin: 0 0 0.938rem;
  color: var(--slate);
}

p a.company-link {
  content: '';
  display: block;
  width: 0;
  height: 0.063rem;
  position: relative;
  bottom: 0.37em;
  color: var(--green);
  transition: var(--transition);
  opacity: 0.5;
}

::selection {
  background-color: var(--lightest-navy);
  color: var(--lightest-slate);
}

/* Breakpoints */

@media (max-width: 67.5rem) {
  main.fill-height {
    padding: 0 6.25rem;
  }
}

@media screen and (max-width: 48rem) {
  main.fill-height {
    padding: 0 3.125rem 4rem;
  }
}

@media (max-width: 30rem) {
  main.fill-height {
    padding: 0 2.25rem 4rem;
  }
}
