.contact-section {
  max-width: 37.5rem;
  margin: 0 auto 6.25rem;
  text-align: center;
}

.contact-section .overline {
  display: block;
  margin-bottom: 1.25rem;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: var(--fz-md);
  font-weight: 400;
}

.contact-section .overline::before {
  bottom: 0;
  font-size: var(--fz-sm);
}

.contact-section .title {
  font-size: clamp(2.5rem, 5vw, 3.75rem);
}

.contact-section .email-link {
  color: var(--green);
  background-color: transparent;
  border: 0.063rem solid var(--green);
  border-radius: var(--border-radius);
  padding: 1.25rem 1.75rem;
  font-size: var(--fz-sm);
  font-family: var(--font-mono);
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: var(--transition);
  margin-top: 3.125rem;
}

/****************** Breakpoints ******************/

@media (max-width: 48rem) {
  .contact-section {
    margin: 0 auto 3.125rem;
  }
}
