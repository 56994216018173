.header-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--nav-height);
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 0 3.125rem;
  background-color: rgba(10 25 47 / 95%);
  transition: var(--transition);
  filter: none;
  pointer-events: auto;
  user-select: auto;
}

.header-main.hide {
  top: calc(var(--nav-height) * -1);
}

.header-menu {
  display: none;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-logo {
  height: 2em;
  width: 2em;
}

.header-button-container.header-button-container {
  display: flex;
  justify-content: center;
  margin-left: 0.938rem;
  height: 2.438rem;
  width: 6.077rem;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-list li {
  margin: 0 0.313rem;
  position: relative;
  counter-increment: item 1;
  font-size: var(--fz-xs);
}

.nav-list li a::before {
  content: '0' counter(item) '.';
  margin-right: 0.313rem;
  color: var(--green);
  font-size: var(--fz-xxs);
  text-align: right;
}

.nav-list li a {
  padding: 0.625rem;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: var(--menu-text-color);
  position: relative;
  transition: var(--transition);
  line-height: var(--text-line-height);
}

/* Breakpoints */
@media screen and (min-width: 48rem) {
  .header-menu {
    display: flex;
    align-items: center;
  }
}
