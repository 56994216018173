:root {
  --primary-color: #0a192f;
  --sidebar-color: #112240;
  --green: rgb(100 255 218);
  --white: #e6f1ff;
  --menu-text-color: #bbc5e5;
  --header-spacing: 2rem;
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system,
    system-ui, sans-serif;
  --border-radius: 0.25rem;
  --fz-xxs: 0.75rem;
  --fx-xs: 0.813rem;
  --fz-xs: 0.813rem;
  --fz-sm: 0.875rem;
  --fz-md: 1.1rem;
  --fz-lg: 1.125rem;
  --fz-xl: 1.375rem;
  --fz-xxl: 1.375rem;
  --fz-heading: 2rem;
  --nav-height: 6.25rem;
  --text-line-height: 1.056;
  --nav-scroll-height: 4.375rem;
  --navy-shadow: rgba(2 12 27 / 70%);
  --light-navy: #112240;
  --lightest-navy: rgb(35 53 84);
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --slate: #8892b0;
  --tab-height: 2.625rem;
}

/* https://piccalil.li/blog/a-modern-css-reset */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* TODO : Delete */

/* The following is for skip link debugging */

/* :not(.skip-link):focus {
  outline: 5px solid red;
} */

/* Set core root defaults */
html,
body {
  height: 100%;
  background-color: var(--primary-color);
  line-height: var(--text-line-height);
  scroll-behavior: smooth;
}

html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizespeed;
  line-height: 1.5;
  font-family: var(--font-sans);
  color: rgb(136 146 176);
}

a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

svg {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  fill: currentcolor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.625rem;
  font-weight: 600;
  color: var(--lightest-slate);
  line-height: 1.1;
}

section {
  margin: 0 auto;
  padding: 6.25rem 0 0;
  max-width: 62.5rem;
}

.numbered-heading {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0.625rem 0 2.5rem;
  width: 100%;
  font-size: clamp(1.625rem, 5vw, var(--fz-heading));
  white-space: nowrap;
}

.numbered-heading::before {
  position: relative;

  /* bottom: 0.25rem; */
  counter-increment: section 1;
  content: '0' counter(section) '.';
  margin-top: 0.25rem;
  margin-right: 0.625rem;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: clamp(var(--fz-md), 3vw, var(--fz-xl));
  font-weight: 400;
}

.numbered-heading:not(.overline)::after {
  content: '';
  display: block;
  position: relative;
  top: -0.313rem;
  width: 18.75rem;
  height: 0.063rem;
  margin-left: 1.25rem;
  background-color: var(--lightest-navy);
}

button {
  cursor: pointer;
  border: 0;
  border-radius: 0;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* App */
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Hamburger menu */
.hamburger-menu {
  display: block;
}

/* Misc */
.blur {
  filter: blur(0.313rem) brightness(0.7);
  transition: var(--transition);
  pointer-events: none;
  user-select: none;
}

/* Link to youtube video about how to implement skip-link */

/* https://www.youtube.com/watch?v=VUR0I5mqq7I */
.skip-link {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #333;
  color: white;
  padding: 0.5rem;
  translate: 0 -100%;
  transition: translate 150ms ease-in-out;
}

.skip-link:focus {
  translate: 0;
}

svg.feather {
  fill: none;
}

img,
svg,
.image-wrapper {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.image-wrapper-constrained {
  display: inline-block;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.image-wrapper img {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.overline {
  color: var(--green);
  font-family: var(--font-mono);
  font-size: var(--fz-md);
  font-weight: 400;
}

/******************** Breakpoints ********************/
@media screen and (min-width: 48rem) {
  .hamburger-menu {
    display: none;
  }

  .numbered-heading::after {
    width: 100%;
  }
}

@media (max-width: 67.5rem) {
  .numbered-heading::after {
    width: 12.5rem;
  }
}

@media (max-width: 48rem) {
  section {
    padding: 5rem 0 0;
  }
}

@media (max-width: 30rem) {
  section {
    padding: 3.75rem 0 0;
  }

  .numbered-heading::before {
    margin-bottom: 0.188rem;
    margin-right: 0.313rem;
  }
}
