:root {
  --selected-tab: 0;
}

.jobs-section {
  max-width: 43.75rem;
}

.jobs-section .inner {
  display: block;
}

.jobs-tabs {
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0;
  margin: 0;
  list-style: none;
}

.jobs-tab {
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  transition: var(--transition);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0 1.25rem 0.125rem;
  border-left: 0.125rem solid var(--lightest-navy);
  background-color: transparent;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;
}

.jobs-selected-tab {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 0.125rem;
  height: var(--tab-height);
  border-radius: var(--border-radius);
  background: var(--green);
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.jobs-tab-0 {
  transform: translateY(calc(0 * var(--tab-height)));
}

.jobs-tab-1 {
  transform: translateY(calc(1 * var(--tab-height)));
}

.jobs-tab-2 {
  transform: translateY(calc(2 * var(--tab-height)));
}

.jobs-tab-3 {
  transform: translateY(calc(3 * var(--tab-height)));
}

.jobs-tab-4 {
  transform: translateY(calc(4 * var(--tab-height)));
}

.jobs-tab-panels {
  position: relative;
  width: 100%;
  margin-left: 1.25rem;
}

.jobs-tab-panel {
  width: 100%;
  height: auto;
  padding: 0.625rem 0.313rem;
}

.jobs-tab-panel h3 {
  margin-bottom: 0.125rem;
  font-size: var(--fz-xxl);
  font-weight: 500;
  line-height: 1.3;
}

.jobs-tab-panel h3 .jobs-company {
  color: var(--green);
}

.jobs-tab-panel .jobs-range {
  margin-bottom: 1.563rem;
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.jobs-tab-panel ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: var(--fz-lg);
}

.jobs-tab-panel ul li {
  position: relative;
  padding-left: 1.875rem;
  margin-bottom: 0.625rem;
}

.jobs-tab-panel ul li::before {
  content: '▹';
  position: absolute;
  left: 0;
  color: var(--green);
}

@media (min-width: 37.5rem) {
  .jobs-section .inner {
    display: flex;
  }
}

@media (min-width: 43.75rem) {
  .jobs-section .inner {
    min-height: 21.25rem;
  }
}
