/* 6. Snappy Sliding Hamburger Menu */

/* https://alvarotrigo.com/blog/hamburger-menu-css/ */

/* https://codepen.io/alvarotrigo/pen/yLzaPVJ */

.menu-box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: min(75vw, 25rem);
  height: 100%;
  margin: 0;
  padding: 5rem 0;
  list-style: none;
  background-color: var(--sidebar-color);
  box-shadow: 0.125rem 0.125rem 0.375rem rgba(0 0 0 / 40%);
  transition-duration: 0.25s;
}

.menu-box li {
  margin: 0 auto 1.25rem;
  font-size: clamp(var(--fz-sm), 4vw, var(--fz-lg));
}

#menu-toggle {
  opacity: 0;
}

.menu-btn {
  display: block;
  position: relative;
  width: 1.625rem;
  height: 1.625rem;
  cursor: pointer;
  z-index: 1;
}

.menu-btn > span,
.menu-btn > span::before,
.menu-btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background-color: #616161;
  transition-duration: 0.25s;
}

.menu-btn > span::before {
  content: '';
  top: -0.5rem;
}

.menu-btn > span::after {
  content: '';
  top: 0.5rem;
}

.menu-item-num {
  text-align: center;
  color: var(--green);
  font-size: var(--fz-sm);
  font-family: var(--font-mono);
}

.menu-item {
  display: block;
  padding: 0 1.5rem 0.75rem;
  font-family: var(--font-mono);
  font-size: var(--fz-lg);
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
  text-align: center;
  color: var(--menu-text-color);
}

.menu-item:hover {
  background-color: #cfd8dc;
}

.sidebar-button-li {
  display: flex;
  justify-content: center;
}

.sidebar-button-container.sidebar-button-container {
  margin: 10% auto 0;
  padding: 1.125rem 3.125rem;
}

.sidebar-button.sidebar-button {
  font-weight: bold;
}

#menu-toggle:checked + .menu-btn > span {
  transform: rotate(45deg);
}

#menu-toggle:checked + .menu-btn > span::before {
  top: 0;
  transform: rotate(0deg);
}

#menu-toggle:checked + .menu-btn > span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu-toggle:checked ~ .menu-box {
  right: 0 !important;
}
