.about-section {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s,
    transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
  max-width: 56.25rem;
}

.about-section .inner {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 3.125rem;
}

.about-skills ul.skills-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(8.75rem, 12.5rem));
  gap: 0 0.625rem;
  padding: 0;
  margin: 1.25rem 0 0;
  overflow: hidden;
  list-style: none;
}

.about-skills ul.skills-list li {
  position: relative;
  margin-bottom: 0.625rem;
  padding-left: 1.25rem;
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.about-skills ul.skills-list li::before {
  content: '▹';
  position: absolute;
  left: 0;
  color: var(--green);
  font-size: var(--fz-sm);
  line-height: 0.75rem;
}

.about-headshot {
  position: relative;
  max-width: 18.75rem;
}

.about-headshot-wrapper {
  box-shadow: 0 0.625rem 1.875rem -0.938rem var(--navy-shadow);
  transition: var(--transition);
  display: block;
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--green);
}

.about-headshot-wrapper::after {
  border: 0.125rem solid var(--green);
  top: 1.25rem;
  left: 1.25rem;
  z-index: -1;
}

.about-headshot-wrapper::before,
.about-headshot-wrapper::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.about-image-wrapper {
  position: relative;
  border-radius: var(--border-radius);
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1);
  transition: var(--transition);
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.about-image-container {
  max-width: 31.25rem;
  display: block;
}

.about-image-container img {
  max-width: 100%;
  display: block;
  position: static;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.about-image-placeholder {
  opacity: 0;
  transition: opacity 500ms linear 0s;
  background-color: rgb(232 232 232);
  position: absolute;
  inset: 0;
  object-fit: cover;
}

@media (max-width: 30rem) {
  .about-skills ul.skills-list {
    grid-template-columns: repeat(1, minmax(8.75rem, 12.5rem));
  }
}

@media (max-width: 48rem) {
  .about-section {
    margin-bottom: 4rem;
  }
}

@media (max-width: 48rem) {
  .about-section .inner {
    display: block;
  }

  .about-headshot {
    margin: 3.125rem auto 0;
    width: 70%;
  }
}
