.social-media-container {
  width: 2.5rem;
  position: fixed;
  bottom: 0;
  left: 2.5rem;
  right: auto;
  z-index: 10;
  color: var(--light-slate);
}

.social-media-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-media-list::after {
  content: '';
  display: block;
  width: 0.063rem;
  height: 13rem;
  margin: 0 auto;
  background-color: var(--light-slate);
}

.social-media-list li a {
  padding: 0.625rem;
}

svg {
  transition: all 300ms;
}

svg.feather {
  fill: none;
}

svg:hover {
  transform: translate(0, -0.5rem);
  color: var(--green);
}

.social-media-list li a svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Breakpoints */
@media screen and (max-width: 48rem) {
  .social-media-container {
    display: none;
  }
}

@media (max-width: 67.5rem) {
  .social-media-container {
    left: 1.25rem;
    right: auto;
  }
}
