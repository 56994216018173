.projects-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.project-portfolio {
  /* visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s; */

  position: relative;
  display: grid;
  gap: 0.625rem;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
}

.project-portfolio:not(:last-of-type) {
  margin-bottom: 6.25rem;
}

.project-portfolio .project-content {
  position: relative;
  grid-area: 1 / 1 / -1 / 7;
}

.project-portfolio:nth-of-type(2n + 1) .project-content {
  grid-column: 7 / -1;
  text-align: right;
}

.project-portfolio .project-overline {
  margin: 0.625rem 0;
  color: var(--green);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  font-weight: 400;
}

.project-portfolio .project-title {
  color: var(--light-slate);
  font-size: clamp(1.5rem, 5vw, 1.75rem);
}

.projects-list a {
  position: relative;
  z-index: 1;
}

.projects-list .project-description {
  box-shadow: 0 0.625rem 1.875rem -0.938rem var(--navy-shadow);
  transition: var(--transition);
  position: relative;
  z-index: 2;
  padding: 1.563rem;
  border-radius: var(--border-radius);
  background-color: var(--light-navy);
  color: var(--light-slate);
  font-size: var(--fz-lg);
}

.project-portfolio .project-tech-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  margin: 1.563rem 0 0.625rem;
  padding: 0;
  list-style: none;
}

.project-portfolio:nth-of-type(2n + 1) .project-tech-list {
  justify-content: flex-end;
}

.project-portfolio .project-tech-list li {
  margin: 0 1.25rem 0.313rem 0;
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  white-space: nowrap;
}

.project-portfolio:nth-of-type(2n + 1) .project-tech-list li {
  margin: 0 0 0.313rem 1.25rem;
}

.project-portfolio .project-links {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.625rem;
  color: var(--lightest-slate);
}

.project-portfolio:nth-of-type(2n + 1) .project-links {
  justify-content: flex-end;
  margin-left: 0;
  margin-right: -0.625rem;
}

.project-links a {
  position: relative;
  z-index: 1;
}

.project-portfolio:nth-of-type(2n) .project-links a {
  padding-left: 0;
  padding-right: 1.5rem;
}

.project-portfolio:nth-of-type(2n + 1) .project-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.625rem 0.625rem 0;
}

.project-portfolio .project-links a svg {
  width: 1.25rem;
  height: 1.25rem;
}

.project-portfolio .project-image {
  box-shadow: 0 0.625rem 1.875rem -0.938rem var(--navy-shadow);
  transition: var(--transition);
  grid-area: 1 / 6 / -1 / -1;
  position: relative;
  z-index: 1;
}

.project-portfolio:nth-of-type(2n + 1) .project-image {
  grid-column: 1 / 8;
}

.project-portfolio .project-image a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
  transition: var(--transition);
  background-color: var(--navy);
  mix-blend-mode: screen;
}

.project-portfolio .project-image .img {
  border-radius: var(--border-radius);
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1) brightness(90%);
}

.highlighted-text {
  color: var(--green);
}

.weather-external {
  margin-top: -0.25rem;
  margin-left: 1rem;
}

/****************** Breakpoints ******************/
@media (min-width: 48rem) {
  .project-portfolio .project-title {
    margin: 0 0 1.25rem;
  }
}

@media (max-width: 67.5rem) {
  .project-portfolio .project-content {
    grid-column: 1 / 9;
  }

  .project-portfolio:nth-of-type(2n + 1) .project-content {
    grid-column: 5 / -1;
  }
}

@media (max-width: 48rem) {
  .project-portfolio {
    box-shadow: 0 0.625rem 1.875rem -0.938rem var(--navy-shadow);
    transition: var(--transition);
  }

  .project-portfolio .project-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    grid-column: 1 / -1;
    padding: 2.5rem 2.5rem 1.875rem;
    z-index: 5;
  }

  .project-portfolio:not(:last-of-type) {
    margin-bottom: 4.375rem;
  }

  .project-portfolio:nth-of-type(2n + 1) .project-content {
    grid-column: 1 / -1;
    padding: 2.5rem 2.5rem 1.875rem;
    text-align: left;
  }

  .project-portfolio .project-title {
    color: var(--white);
  }

  .project-portfolio .project-title a {
    position: static;
  }

  .project-portfolio .project-description {
    padding: 1.25rem 0;
    background-color: transparent;
    box-shadow: none;
  }

  .project-portfolio .project-tech-list {
    margin: 0.625rem 0;
  }

  .project-portfolio:nth-of-type(2n + 1) .project-tech-list {
    justify-content: flex-start;
  }

  .project-portfolio .project-tech-list li {
    margin: 0 0.625rem 0.313rem 0;
    color: var(--lightest-slate);
  }

  .project-portfolio:nth-of-type(2n + 1) .project-tech-list li {
    margin: 0 0.625rem 0.313rem 0;
  }

  .project-portfolio:nth-of-type(2n) .project-links {
    margin-left: 0;
  }

  .project-portfolio:nth-of-type(2n + 1) .project-links {
    justify-content: flex-start;
    margin-left: -0.625rem;
    margin-right: 0;
  }

  .project-portfolio .project-image {
    grid-column: 1 / -1;
    height: 100%;
    opacity: 0.15;
  }

  .project-portfolio:nth-of-type(2n + 1) .project-image {
    grid-column: 1 / -1;
  }

  .project-portfolio .project-image .img {
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(50%);
  }
}

@media (max-width: 30rem) {
  .project-portfolio:not(:last-of-type) {
    margin-bottom: 1.875rem;
  }

  .project-portfolio:nth-of-type(2n + 1) .project-content {
    padding: 1.563rem 1.563rem 1.25rem;
  }
}
